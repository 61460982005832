





































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import WidgetMixins from '@/mixins/WidgetMixins';
import { namespace } from 'vuex-class';
import ToolBar from '@/components/widgets/ToolBar.vue';
import { INotifications, IGraphData } from '@/types/types';
import moment from 'moment';
//firebase
const appModule = namespace('apps');
const notificationModule = namespace('notifications');
const dashboardModule = namespace('dashboard');
@Component({
  components: {
    ToolBar,
    Notifications: loadWidget('pages/Notifications'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    'internet-connection': loadWidget('pages/InternetConnection'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Applications extends mixins(WidgetMixins) {
  placeholder: string = require('@/assets/images/notification-list.jpg');
  @appModule.Getter('getApps') dashboard!: Array<object>;
  @dashboardModule.Getter('getSMSDashboardStatistics') dashboardCount!: any;
  @dashboardModule.Getter('getSMSDashboardGraph') dashboardGraph!: any;

  @notificationModule.Getter('getAllNotifications')
  notifications!: ReadonlyArray<INotifications>;

  showPushNotificationToast = false;
  markAsRead: boolean = true;
  tab: any = null;
  text: string = 'sample test';
  items: Array<string> = ['All Updates', 'Popular'];
  show: boolean = false;
  date: string = new Date().toISOString().substr(0, 10);
  menu2: boolean = false;
  statuses: Array<string> = ['DAILY', 'MONTHLY', 'YEARLY'];
  status: string = 'DAILY';

  chartOptions: any = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        endingShape: 'rounded',
      },
    },
    colors: ['#e20639', '#04bf5b'],
    legend: {
      show: true,
      fontSize: '11px',
      fontWeight: '500',
      fontFamily: 'Montserrat, sans-serif',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          cssClass: 'ft',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Total Customers',
      },
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          cssClass: 'ft',
        },
        formatter: (val: any) => {
          return val;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  areaChartOptions: any = {
    chart: {
      type: 'area',
      height: 350,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
  };

  series: any = [
    {
      name: 'Total Customers',
      data: [],
    },
  ];

  senderIdSeries: any = [
    {
      name: 'Total Pending IDs',
      data: [],
    },
    {
      name: 'Total Approved IDs',
      data: [],
    },
  ];

  isNotificationTurnedOn = false;
  isNotificationDenied = false;
  headers: any = [
    {
      text: 'Date',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'Customer', value: 'calories' },
    { text: 'Products', value: 'fat' },
    { text: 'Details', value: 'carbs' },
    { text: 'Price', value: 'protein' },
    { text: 'Delivery Cost', value: 'iron' },
  ];

  emptyState: any = {
    notification: require('@/assets/images/music.png'),
    popular: require('@/assets/images/commerce-and-shopping.png'),
  };

  @Watch('dashboardGraph')
  onDashboardGraphChange(payload: any): void {
    const xAxis = [...new Set([...payload.customersGraph.map((item: IGraphData) => item.label)])];
    const senderIdXAxis = [
      ...new Set([
        ...payload.senderIDGraph?.pendingSenderIDGraph?.map((item: IGraphData) => item.label),
        ...payload.senderIDGraph?.approvedSenderIDGraph?.map((item: IGraphData) => item.label),
      ]),
    ];

    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: xAxis,
      },
    };
    this.areaChartOptions = {
      ...this.areaChartOptions,
      xaxis: {
        categories: senderIdXAxis,
      },
    };
    this.series[0].data = payload.customersGraph.map((item: IGraphData) => item.value);
    this.senderIdSeries[0].data = payload.senderIDGraph?.pendingSenderIDGraph.map((item: IGraphData) => item.value);
    this.senderIdSeries[1].data = payload.senderIDGraph?.approvedSenderIDGraph.map((item: IGraphData) => item.value);
  }

  @Watch('dashboardCount')
  onDashboardCountChange(payload: any): void {}

  // turning on notifications here

  // getting notifications token

  //sending notifications to server

  removeNotification(id: string): void {
    this.$store.dispatch('notifications/removeNotifications', { id });
  }

  clearAllNotifications(): void {
    this.$store.dispatch('notifications/clearAllNotifications');
  }

  markNotificationAsRead(notificationId: string): void {
    this.$store.dispatch(`notifications/markAsRead`, { id: notificationId });
  }

  created() {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('dashboard/dashboardStatistics');
    this.$store.dispatch('dashboard/dashboardGraph');
  }
}
